import React from 'react'
import { Typography, Card, Row, Col } from 'antd'
import MainLayout from '@layouts/MainLayout'
import { OutboundLink } from 'gatsby-plugin-gtag'

export default () => (
  <MainLayout>
    <div className='flex flex-col'>
      <Typography.Title className='text-primary-color font-headers font-bold'>
        Hi, and welcome.
      </Typography.Title>
      <Typography.Paragraph className='font-body text-xl'>
        I&apos;m a <b>software engineer and web developer</b> for five years
        now, among other things:{' '}
        <OutboundLink href='https://porch.severian.dev'>
          practicing Stoic
        </OutboundLink>
        , science fiction reader and writer,{' '}
        <OutboundLink href='https://notes.severian.dev'>
          note-taking & productivity enthusiast (not public yet)
        </OutboundLink>
        .
      </Typography.Paragraph>
      <Typography.Paragraph className='font-body text-xl'>
        This is the hub of all my ongoing projects and endeavors. Will probably
        start writing about tech, philosophy and cognition more over the coming
        weeks.
      </Typography.Paragraph>
      <Typography.Paragraph className='font-body text-xl'>
        Opinions expressed here are my own. My email is <i>yo@[this domain]</i>{' '}
        should you want to contact me. Please enjoy your stay.
      </Typography.Paragraph>
    </div>
    <div className='flex flex-col'>
      <Typography.Title
        level={3}
        className='text-primary-color font-headers font-bold'
      >
        Projects
      </Typography.Title>
      <Row type='flex' justify='center' gutter={20}>
        <Col xs={22} sm={22} md={12} lg={12} xl={12} xxl={12}>
          <Card
            className='mb-6'
            size='small'
            title={
              <span className='font-semibold text-xl text-current'>Porch</span>
            }
            extra={
              <span className='uppercase font-bold tracking-widest text-red-800'>
                Deployed
              </span>
            }
          >
            <p className='text-base '>
              Stoic inspiration in your browser, daily refreshed with scenic
              backgrounds from Unsplash. Also available as a Chrome extension.
            </p>
            <p className='text-base '>
              <OutboundLink
                href='https://porch.severian.dev'
                className='underline'
              >
                Check it out here.
              </OutboundLink>
            </p>
          </Card>
        </Col>
        <Col xs={22} sm={22} md={12} lg={12} xl={12} xxl={12}>
          <Card
            className='mb-6'
            size='small'
            title={
              <span className='font-semibold text-xl text-current'>
                Obscurify
              </span>
            }
            extra={
              <span className='uppercase font-bold tracking-widest text-green-800'>
                Planning
              </span>
            }
          >
            <p className='text-base '>
              Anonymous Spotify playlist sharing service. Get a unique link to
              let anyone clone your playlist. More details to come soon.
            </p>
          </Card>
        </Col>
        <Col xs={22} sm={22} md={12} lg={12} xl={12} xxl={12}>
          <Card
            className='mb-6'
            size='small'
            title={
              <span className='font-semibold text-xl text-current'>
                Aldebaran
              </span>
            }
            extra={
              <span className='uppercase font-bold tracking-widest text-green-800'>
                Planning
              </span>
            }
          >
            <p className='text-base '>
              A personal management system, built with privacy in mind. Own your
              data. More details to come soon.
            </p>
          </Card>
        </Col>
      </Row>
    </div>
  </MainLayout>
)
